import { createRouter, createWebHashHistory } from 'vue-router'

function isAuthenticated() {
  // Exemplo básico usando localStorage (adaptar conforme sua implementação)
  return localStorage.getItem('user') !== null;
}

const routes = [

  //ROUTES FOR ADMIN
  {
    path: '/',
    name: 'admin_login',
    component: () => import('../views/admin/login.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/dashboard',
    name: 'admin_dashboard',
    component: () => import('../views/admin/dashboard.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/perfil',
    name: 'admin_profile',
    component: () => import('../views/admin/profile.vue'),
    meta: { requiresAuth: false } 
  },

  //CONFIGURACAO
  {
    path: '/admin/configuracao',
    name: 'admin_config',
    component: () => import('../views/admin/configuracao/configuracao_view.vue'),
    meta: { requiresAuth: true } 
  },

  //SECRETARY
  {
    path: '/admin/secretaria/encomendas',
    name: 'admin_secretary_pedidos',
    component: () => import('../views/admin/secretaria/pedido_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/detalhes-pedido/:id/',
    name: 'detalhes_pedido',
    component: () => import('../views/admin/secretaria/detalhes_pedido.vue'),
    meta: { requiresAuth: true } 
  },

  //FINANCES
  {
    path: '/admin/finances/despesas',
    name: 'finances_despesas',
    component: () => import('../views/admin/financas/despesa_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/finances/pagamento-parceiros',
    name: 'pagamento_parceiros',
    component: () => import('../views/admin/financas/pag_parc_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/finances/pagamento-parceiros-historico/:id/',
    name: 'pagamento_parceiros_historico',
    component: () => import('../views/admin/financas/pag_parc_history_view.vue'),
    meta: { requiresAuth: true } 
  },

  //RELATÓRIOS
  {
    path: '/admin/relatorios/fluxo-de-caixa',
    name: 'relatorio_fluxo_de_caixa',
    component: () => import('../views/admin/relatorio/fluxo_de_caixa_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/relatorios/encomendas',
    name: 'relatorio_encomendas',
    component: () => import('../views/admin/relatorio/encomendas_view.vue'),
    meta: { requiresAuth: true } 
  },

  //INFORMATION
  {
    path: '/admin/informacao/noticias',
    name: 'news_view',
    component: () => import('../views/admin/information/news_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/informacao/destaques',
    name: 'destaque_view',
    component: () => import('../views/admin/information/destaque_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/informacao/destaque-produto',
    name: 'destaque_produto_view',
    component: () => import('../views/admin/information/destaque_produto_view.vue'),
    meta: { requiresAuth: true } 
  },

  //AUTENTICAÇÃO
  {
    path: '/admin/autenticacao/users',
    name: 'admin_autenticacao_users',
    component: () => import('../views/admin/autenticacao/users_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/autenticacao/entregadores',
    name: 'admin_autenticacao_entregadores',
    component: () => import('../views/admin/autenticacao/entregador_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/autenticacao/clientes',
    name: 'admin_autenticacao_clientes',
    component: () => import('../views/admin/autenticacao/clientes_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/autenticacao/comerciantes',
    name: 'admin_autenticacao_comerciantes',
    component: () => import('../views/admin/autenticacao/vendedores_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/autenticacao/logout',
    name: 'admin_autenticacao_logout',
    component: () => import('../views/admin/logout.vue'),
    meta: { requiresAuth: true } 
  },

  //UTILS
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('../views/404_view.vue'),
    meta: { requiresAuth: false } 
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// Guarda de navegação para verificar autenticação
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
